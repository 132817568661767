import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109')
];

export const server_loads = [0,3,7,9,15,16,2];

export const dictionary = {
		"/": [~17],
		"/app": [~20,[3]],
		"/app/academic/class-placement": [~21,[3,4]],
		"/app/academic/classes": [~22,[3,4]],
		"/app/academic/classes/[classId]": [~23,[3,4]],
		"/app/academic/continuation": [~24,[3,4]],
		"/app/academic/overview": [~25,[3,4]],
		"/app/admin/academic-years": [~26,[3,5]],
		"/app/admin/academic-years/[academicYearId]": [~27,[3,5]],
		"/app/admin/delete-students": [28,[3,5]],
		"/app/admin/users": [~29,[3,5]],
		"/app/admin/users/[staffId]": [~30,[3,5]],
		"/app/admin/value-centers": [~31,[3,5]],
		"/app/admin/value-centers/[valueCenterId]": [~32,[3,5]],
		"/app/admission/admission-groups": [33,[3,6]],
		"/app/admission/admission-steps": [~34,[3,6]],
		"/app/admission/admission-steps/[stepId]": [~35,[3,6]],
		"/app/admission/announcements": [~36,[3,6]],
		"/app/admission/announcements/[announcementId]": [~37,[3,6]],
		"/app/admission/overview": [~38,[3,6]],
		"/app/admission/registrations": [~39,[3,6]],
		"/app/admission/reports": [40,[3,6]],
		"/app/admission/schools": [~41,[3,6]],
		"/app/admission/schools/[schoolId]": [~42,[3,6]],
		"/app/admission/students": [~43,[3,6]],
		"/app/admission/students/form/existing": [~44,[3,6]],
		"/app/admission/students/form/new": [~45,[3,6]],
		"/app/class/[classId]/sessions": [~46,[3,7]],
		"/app/class/[classId]/sessions/[sessionId]": [~47,[3,7]],
		"/app/class/[classId]/students": [~48,[3,7]],
		"/app/class/[classId]/timetable": [~49,[3,7]],
		"/app/finance/bills": [50,[3,8]],
		"/app/finance/bills/bill-templates": [~51,[3,8]],
		"/app/finance/bills/insert-bills": [~52,[3,8]],
		"/app/finance/monitoring": [~53,[3,8]],
		"/app/finance/overview": [~54,[3,8]],
		"/app/finance/payment-categories": [~55,[3,8]],
		"/app/finance/payment-categories/[paymentCategoryId]/discounts": [~56,[3,8]],
		"/app/finance/payment-categories/[paymentCategoryId]/discounts/[discountId]": [~57,[3,8]],
		"/app/finance/payment-categories/[paymentCategoryId]/form": [~58,[3,8]],
		"/app/finance/payment-categories/[paymentCategoryId]/payment-types": [~59,[3,8]],
		"/app/finance/payment-categories/[paymentCategoryId]/payment-types/[paymentTypeId]": [~60,[3,8]],
		"/app/finance/reports": [61,[3,8]],
		"/app/finance/reports/[startDate]/[endDate]/bills-per-day": [~62,[3,8]],
		"/app/finance/reports/[startDate]/[endDate]/bills-per-month": [~63,[3,8]],
		"/app/finance/reports/[startDate]/[endDate]/bills-per-student": [~64,[3,8]],
		"/app/finance/reports/[startDate]/[endDate]/bills-this-year": [~65,[3,8]],
		"/app/finance/reports/[startDate]/[endDate]/receivables-per-student": [~66,[3,8]],
		"/app/finance/reports/[startDate]/[endDate]/revenue-per-day": [~67,[3,8]],
		"/app/finance/reports/[startDate]/[endDate]/revenue-per-month": [~68,[3,8]],
		"/app/finance/reports/[startDate]/[endDate]/revenue-per-student": [~69,[3,8]],
		"/app/finance/reports/[startDate]/[endDate]/revenue-per-transaction": [~70,[3,8]],
		"/app/finance/students": [~71,[3,8]],
		"/app/guardian/[guardianId]": [72,[3,9]],
		"/app/guardian/[guardianId]/biodata": [~73,[3,9]],
		"/app/home/announcements": [~74,[3,10]],
		"/app/home/announcements/[announcementId]": [~75,[3,10]],
		"/app/internal/document-categories": [~76,[3,11]],
		"/app/internal/document-categories/[documentCategoryId]": [~77,[3,11]],
		"/app/internal/document-tags": [~78,[3,11]],
		"/app/internal/document-tags/[documentTagId]": [~79,[3,11]],
		"/app/internal/documents": [~80,[3,11]],
		"/app/internal/documents/[documentTypeId]": [~81,[3,11]],
		"/app/internal/documents/[documentTypeId]/documents": [~82,[3,11]],
		"/app/internal/documents/[documentTypeId]/documents/[documentId]": [~83,[3,11]],
		"/app/internal/documents/[documentTypeId]/documents/[documentId]/viewer": [~84,[3,11]],
		"/app/inventory/assets": [85,[3,12]],
		"/app/inventory/categories": [86,[3,12]],
		"/app/inventory/kits": [87,[3,12]],
		"/app/inventory/locations": [88,[3,12]],
		"/app/inventory/tags": [89,[3,12]],
		"/app/people/guardians": [~90,[3,13]],
		"/app/people/guardians/new": [~91,[3,13]],
		"/app/people/staffs": [~92,[3,13]],
		"/app/people/students": [~93,[3,13]],
		"/app/settings/2fa": [~94,[3,14]],
		"/app/settings/2fa/disable": [~95,[3,14]],
		"/app/settings/2fa/verify": [~96,[3,14]],
		"/app/settings/change-password": [~97,[3,14]],
		"/app/staff/[staffId]": [98,[3,15]],
		"/app/staff/[staffId]/biodata": [~99,[3,15]],
		"/app/student/[studentId]": [100,[3,16]],
		"/app/student/[studentId]/bills": [~101,[3,16]],
		"/app/student/[studentId]/bills/[billId]": [~102,[3,16]],
		"/app/student/[studentId]/biodata": [~103,[3,16]],
		"/app/student/[studentId]/invoices": [~104,[3,16]],
		"/app/student/[studentId]/invoices/[invoiceId]/approve": [~105,[3,16]],
		"/app/student/[studentId]/invoices/[invoiceId]/reallocate": [~106,[3,16]],
		"/app/student/[studentId]/invoices/[invoiceId]/receipt/pdf": [~107],
		"/app/student/[studentId]/others": [~108,[3,16]],
		"/app/student/[studentId]/others/[noteId]": [~109,[3,16]],
		"/(auth)/login": [~18,[2]],
		"/(auth)/verify": [~19,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';